<template lang="pug">
    section(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/login_bg.jpg') + ')'} ")
        div(id="load_screen")
            div.loader
                div.loader-content
                    div.spinner-grow.align-self-center

        div.form-container.outer
            div.form-form
                div.form-form-wrap
                    div.form-container
                        div.form-content
                            img.img-fluid(src="../assets/img2/insigne_logo_login.png")
                            p {{ $t('login.login') }} 
                            
                            form.text-left
                                div.form
                                    div.field-wrapper.input(id="username-field")
                                        label(for="username") {{ $t('login.userLabel') }} 
                                        svg.feather.feather-user(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                            path(d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2")
                                            circle(cx="12" cy="7" r="4")
                                        input.form-control(id="username" name="username" type="text" :placeholder="$t('login.userLabel')" v-model="model.Username")
                                        
                                    div.field-wrapper.input.mb-2(id="password-field")
                                        div.d-flex.justify-content-between
                                            label(for="password") {{ $t('login.passwordLabel') }}
                                            //a.forgot-pass-link(href="auth_pass_recovery_boxed.html") Olvidé mi contraseña

                                        svg.feather.feather-lock(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round")
                                            rect(x="3" y="11" width="18" height="11" rx="2" ry="2")
                                            path(d="M7 11V7a5 5 0 0 1 10 0v4")
                                        input.form-control(id="password" name="password" type="password" :placeholder="$t('login.passwordLabel')" v-model="model.password")
                                        
                                        svg.feather.feather-eye(xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" id="toggle-password")
                                            path(d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z")
                                            circle(cx="12" cy="12" r="3")

                                    div.d-sm-flex.justify-content-between
                                        div.field-wrapper
                                            button.btn.btn-dark(type="button" value="" @click="login()") {{ $t('login.btnLogin') }}

                                    p.signup-link {{ $t('login.notRegister') }} 
                                        router-link(to="/register") {{ $t('login.CreateAccount') }}
                            



</template>
<script>
/* eslint-disable */
export default {
    name: 'Login',
    data() {
        return {
            model: {
                Username: '',
                password: ''
            }
        }
    },
    methods: {

        async login() {
            //console.log(this.model)
            /*axios.post(apiURL + 'login', this.model, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.tokenUsuario
            } })*/
            this.openLoading('Espere por favor...')
            axios.post(apiURL + 'Login', this.model)
            .then( (response) => {
                Swal.close()
                if(response.data.sErrors.length == 0){
                    localStorage.CertIdP = response.data.person.idPerson
                    localStorage.setItem("dataPerson", JSON.stringify(response.data.person))
                    localStorage.setItem("CertIdCent", JSON.stringify(response.data.centersToAdmin))
                    localStorage.userToken = response.data.token
                    localStorage.idActualRol = response.data.person.rolList[0].idRol
                    localStorage.sLanguage = response.data.person.sLanguage
                    sessionStorage.setItem("web", "ok");
                    router.push(response.data.person.rolList[0].sRouteRolPath)
                }
                else
                    Swal.fire(response.data.sErrors[0])
            } )
            .catch( (error) => {
                Swal.close()
                Swal.fire(error)
            } )
        }
    },
    beforeMount() {
        //document.body.classList.add('hold-transition', 'login-page')
    },
    destroyed() {
        //document.body.classList.remove('hold-transition', 'login-page')
    },
    mounted() {
        var loaderElement = document.querySelector('#load_screen');
        setTimeout( function() {
            loaderElement.style.display = "none";
        }, 3000);
        console.log(localStorage.CertIdP)
        //if(localStorage.CertIdP != undefined) {
        //    var roles = JSON.parse(localStorage.getItem("dataPerson")).rolList;
        //    router.push(roles.find(o => o.idRol === parseInt(localStorage.idActualRol)).sRouteRolPath)
        //}
    }
}
</script>